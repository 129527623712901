function loadImage(fileurl, panelsHeight, panelsWidth, canvasHeight, canvasWidth, imageOffsetX, imageOffsetY, pitch, leds) {

  const large_image_scale = 10;

  const panel_data = { // 12mm is 24x48, 9mm is 32x64, 6mm is 48x96, 4mm is 64x128
    led_12mm: {
      num_leds_wide: 48,
      num_leds_high: 24,
      led_width_percentage: 0.8,
    },
    led_9mm: {
      num_leds_wide: 64,
      num_leds_high: 32,
      led_width_percentage: 0.9,
    },
    led_6mm: {
      num_leds_wide: 96,
      num_leds_high: 48,
      led_width_percentage: 0.95,
    },
    led_4mm: {
      num_leds_wide: 128,
      num_leds_high: 64,
      led_width_percentage: 1.1,
    },
  }

  var num_panels_wide = panelsWidth;
  var num_panels_high = panelsHeight;
  var selected_panel_data;
  switch(pitch)
  {
    case "4mm":
      selected_panel_data = panel_data.led_4mm;
      break;
    case "6mm":
      selected_panel_data = panel_data.led_6mm;
      break;
    case "9mm":
      selected_panel_data = panel_data.led_9mm;
      break;
    case "12mm":
      selected_panel_data = panel_data.led_12mm;
      break;
    default:
      selected_panel_data = panel_data.led_12mm;
  };


  num_panels_wide = num_panels_wide / 2

  var canvas = document.getElementById("canvas");
  var canvas_s = document.getElementById("canvas_s");
  var canvas_l = document.getElementById("canvas_l");
  var canvas_f = document.getElementById("canvas_f");

  var ctx = canvas.getContext("2d");
  ctx.willReadFrequently = true;
  var ctx_s = canvas_s.getContext("2d");
  ctx_s.willReadFrequently = true;
  var ctx_l = canvas_l.getContext("2d");
  ctx_l.willReadFrequently = true;
  var ctx_f = canvas_f.getContext("2d");

  var img = new Image();

  img.src = fileurl.changingThisBreaksApplicationSecurity;
  img.onload = function () {
    canvas.height = canvasHeight;
    canvas.width = canvasWidth;
    //reset the base canvas for redrawing
    ctx.clearRect(0,0, canvas.width, canvas.height);
    //transfer the image to the first canvas 
    drawImageScaledToCanvas(ctx, img, 0, 0, canvas.width, canvas.height, imageOffsetX, imageOffsetY);

    //do some scaling with the first canvas to get it to the resolution of the LEDs
    canvas_s.width = selected_panel_data.num_leds_wide * num_panels_wide;
    canvas_s.height = selected_panel_data.num_leds_high * num_panels_high
    ctx_s.drawImage(canvas, 0, 0, canvas_s.width, canvas_s.height);
    var small_img_data = ctx_s.getImageData(0, 0, canvas_s.width, canvas_s.height);
    canvas_l.width = selected_panel_data.num_leds_wide * num_panels_wide * large_image_scale;
    canvas_l.height = selected_panel_data.num_leds_high * num_panels_high * large_image_scale;
    ctx_l.fillRect(0, 0, canvas_l.width, canvas_l.height);

    if(leds) {
      //draw each circle so it looks like an LED board
      for (var x = 0; x < canvas_s.width; x++) {
        for (var y = 0; y < canvas_s.height; y++) {
          var led_color_r = small_img_data.data[(x * 4) + (y * canvas_s.width * 4)];
          var led_color_g = small_img_data.data[(x * 4) + (y * canvas_s.width * 4) + 1];
          var led_color_b = small_img_data.data[(x * 4) + (y * canvas_s.width * 4) + 2];
          ctx_l.moveTo(x * large_image_scale + (large_image_scale / 2), y * large_image_scale + (large_image_scale / 2));
          ctx_l.beginPath();
          ctx_l.fillStyle = "rgb(" + led_color_r + "," + led_color_g + "," + led_color_b + ")";
          ctx_l.arc(x * large_image_scale + (large_image_scale / 2), y * large_image_scale + (large_image_scale / 2), large_image_scale * selected_panel_data.led_width_percentage / 2, 0, 2 * Math.PI, false);
          ctx_l.fill();
          ctx_l.closePath();
        }
      }
    }
    canvas_f.width = canvasWidth;
    canvas_f.height = canvasHeight;
    ctx_f.drawImage(canvas_l, 0, 0, canvas_f.width, canvas_f.height);
  };
}

function drawImageScaledToCanvas(ctx, img, x, y, w, h, offsetX, offsetY) {

  if (arguments.length === 2) {
      x = y = 0;
      w = ctx.canvas.width;
      h = ctx.canvas.height;
  }

  var iw = img.width,
      ih = img.height,
      r = Math.min(w / iw, h / ih),
      nw = iw * r, 
      nh = ih * r, 
      cx, cy, cw, ch, ar = 1;

  // decide which gap to fill    
  if (nw < w) ar = w / nw;                             
  if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  //calculate the offset
  var widthRatio = cw / w;
  var heightRatio = ch / h;
  cx = offsetX * widthRatio * -1;
  cy = offsetY * heightRatio * -1;

  // make sure source rectangle is valid
  if (cw > iw) cw = iw;
  if (ch > ih) ch = ih;

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w , h);
}